import React ,{useEffect} from "react";
import ReactDOM from "react-dom";
import "./assets/vendors/style";
import "./styles/app.scss";
import App from "./App";
import {  HashRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./redux/store";
import { onLodingSignIn } from "./redux/actions/authActions";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";
import PickerDateFns from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";



// MSAL configuration
var dev="4a19c9fc-4c2c-45ac-9896-f18edb145ccf" //for localhost
var prod="a12fed77-9592-4a42-a873-75e8b5fcd151"  //for enseignant.enligne.xyz
const configuration={
  auth: {
      clientId: prod, //"4a19c9fc-4c2c-45ac-9896-f18edb145ccf ", // from portal azure
      authority: 'https://login.microsoftonline.com/common/',
      redirectUri: process.env.REACT_APP_FRONTEND_URL,
  },
  cache: {
    cacheLocation: "localStorage",  // Ensure tokens are cached in localStorage to be accessible across tabs
    storeAuthStateInCookie: true   // Useful for handling scenarios in IE or Edge
  }
};

export const publicClientApp = new PublicClientApplication(configuration);
store.dispatch(onLodingSignIn());

ReactDOM.render(
  <Provider store={store}>
  <HashRouter>
   <MuiPickersUtilsProvider utils={PickerDateFns} locale={frLocale}>
   <SnackbarProvider>
    <MsalProvider instance={publicClientApp}>  
      <App />
    </MsalProvider> 
    </SnackbarProvider>
   </MuiPickersUtilsProvider>
   </HashRouter>
   </Provider>
  ,
  document.getElementById("root")
);
